<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">指标列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>
    <v-row dense>
      <h1>开发中,指标管理页面</h1>
    </v-row>
  </v-container>
</template>
<script>
export default {};
</script>

<style></style>
